import Phaser from "phaser";
import Player from "./player.js";
import score from "./score.js";

function createScene(options) {
  return class PlatformerScene extends Phaser.Scene {
    constructor() {
      super({ key: options.name });
    }

    preload() {
      this.load.spritesheet("player", "../assets/character.png", {
        frameWidth: 32,
        frameHeight: 32,
        margin: 0,
        spacing: 0,
      });
      this.load.image("tiles", options.tiles);
      this.load.tilemapTiledJSON(options.tileMapName, options.tileJson);
    }

    create() {
      const map = this.make.tilemap({ key: options.tileMapName });
      const tileset = map.addTilesetImage("mario", "tiles");

      this.back = map.createDynamicLayer("back", tileset);
      this.groundLayer = map.createDynamicLayer("walk", tileset);
      this.front = map.createDynamicLayer("front", tileset);
      this.overlap = map.createDynamicLayer("overlap", tileset);
      this.front.setDepth(10);
      this.front.setScrollFactor(1.1);
      this.back.setScrollFactor(0.7);

      const spawnPoint = map.findObject(
        "Objects",
        (obj) => obj.name === "spawn point"
      );
      this.player = new Player(this, spawnPoint.x, spawnPoint.y);

      // Collide the player against the ground layer - here we are grabbing the sprite property from
      // the player (since the Player class is not a Phaser.Sprite).
      this.groundLayer.setCollisionByProperty({ collides: true });
      this.overlap.setCollisionByProperty({ collides: true });
      this.physics.world.addCollider(this.groundLayer, this.player.sprite);

      this.groundLayer.setTileIndexCallback(1353, hitCoin, this);

      function hitCoin(layer, tile) {
        tile.alpha = 0.2;
        tile.tilemapLayer.removeTileAt(tile.x, tile.y);
        return false;
      }

      const hitGate = (layer, tile) => {
        this.player.sprite.body.velocity.x = 0;
        this.player.sprite.body.velocity.y = 0;
        this.scene.transition({
          target: options.gate,
          // data: null,
          // moveAbove: false,
          // moveBelow: false,
          duration: 0,

          // remove: false,
          sleep: true,
          // allowInput: false,
          // onUpdate: null,
          // onUpdateScope: scene
        });
        return false;
      };

      const hitGate2 = (layer, tile) => {
        this.player.sprite.body.velocity.x = 0;
        this.player.sprite.body.velocity.y = 0;
        this.scene.transition({
          target: 'Fir Cones',
          // data: null,
          // moveAbove: false,
          // moveBelow: false,
          duration: 0,

          // remove: false,
          sleep: true,
          // allowInput: false,
          // onUpdate: null,
          // onUpdateScope: scene
        });
        return false;
      };

      this.scene.scene.events.on("transitionwake",(
        fromScene,
        duration
      ) => {
        this.player.sprite.body.velocity.x = 0;
        this.player.sprite.body.velocity.y = 0;
        this.player.sprite.setPosition(this.player.sprite.x - 32, this.player.sprite.y)
      });

      this.groundLayer.setTileIndexCallback([1190, 1193], hitGate, this);
      this.groundLayer.setTileIndexCallback(1196, hitGate2, this);

      this.physics.add.overlap(
        this.player.sprite,
        this.overlap,
        collectCoin,
        process,
        this
      );

      function collectCoin(player, tile) {
        tile.tilemapLayer.removeTileAt(tile.x, tile.y);
        tile.destroy(tile.x, tile.y); // remove the tile/coin
        score.up(10);
        return false;
      }

      function process(player, tile) {
        return tile.properties.collides;
      }

      this.cameras.main.startFollow(this.player.sprite);
      this.cameras.main.setBounds(0, 0, map.widthInPixels, map.heightInPixels);

      // Help text that has a "fixed" position on the screen
      this.add
        .text(16, 16, options.name, {
          font: "18px arial",
          fill: "#000000",
          padding: { x: 20, y: 10 },
          backgroundColor: "#ffffff",
        })
        .setScrollFactor(0)
        .setDepth(11);
    }

    update(time, delta) {
    
      this.player.update();
      if (this.player.sprite.y > this.groundLayer.height) {
        this.player.destroy();
        this.scene.restart();
        score.reset()
        console.log(this.scene.scene.game.scene.scenes)
        const scenes = this.scene.scene.game.scene.scenes
        scenes.forEach(scene => scene.scene.stop())
        scenes[0].scene.start()
        
      }
    }
  };
}

export default createScene;
