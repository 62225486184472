import Phaser from "phaser";
import createScene from "./scene.js";
// import SecondScene from "./second-scene.js";


const PlatformerScene  = createScene({
  name: 'Harvest Rake',
  tiles: "../assets/mario.png",
  tileMapName: 'map',
  tileJson: '../assets/tileset2.json',
  gate: 'Golden Eagle'
}) 

const SecondScene  = createScene({
  name: 'Golden Eagle',
  tiles: "../assets/mario.png",
  tileMapName: 'map2',
  tileJson: '../assets/level2.json',
  gate: 'Harvest Rake'
  
}) 

const FirCones  = createScene({
  name: 'Fir Cones',
  tiles: "../assets/mario.png",
  tileMapName: 'map2',
  tileJson: '../assets/level3.json',
  gate: 'Harvest Rake'
  
}) 

const config = {
  type: Phaser.AUTO,
  width: window.innerWidth,
  height: window.innerHeight,
  parent: document.querySelector('.fullScreen'),
  fullscreenTarget: document.querySelector('.fullScreen'),
  pixelArt: true,
  backgroundColor: "black",
  scene: [PlatformerScene, SecondScene, FirCones],
  physics: {
    default: "arcade",
    arcade: {
      gravity: { y: 1000 }
    }
  }
};

const game = new Phaser.Game(config);

window.addEventListener('resize', function () {  
  game.scale.resize(window.innerWidth, window.innerHeight);
  game.scale.refresh();
});

console.log(game.device.os.desktop)

if (game.device.os.desktop) {
  console.log('aho')
  document.querySelector('.control').classList.add('hidden')
}


document.querySelector('.full').addEventListener('click', gofull)

function gofull() {
  console.log('full')

  if (game.scale.isFullScreen)
  {
      game.scale.stopFullScreen();
  }
  else
  {
      game.scale.toggleFullscreen();
  }

}